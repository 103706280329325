import logo from "../src/assets/Logo.png";
import email from "../src/assets/svg/email.svg";
import internet from "../src/assets/svg/internet.svg";
import LinkedIn from "../src/assets/svg/linkedin.svg";
import phone from "../src/assets/svg/phone.svg";
import whatsapp from "../src/assets/svg/whatsapp.svg";

import {
  Box,
  BoxName,
  Container,
  Header,
  IconBox,
  Image,
  Name,
  Subtitle,
  Title,
} from "./App.styles";
import Icon from "./components/Icon";

function App() {
  return (
    <Container>
      <Header>
        <Image className="Img" src={logo} alt="" />
      </Header>

      <BoxName>
        <Name>Adrieli Raquel Nascimento Silveira</Name>
        <Subtitle>Diretora</Subtitle>
      </BoxName>

      <Box>
        <Title>Informações Profissionais</Title>
        <IconBox>
          <Icon
            src={whatsapp}
            link="https://api.whatsapp.com/send?phone=5515988100411"
            newTab
          />
          <Icon src={phone} link="tel:5515988100411" newTab />
          <Icon src={email} link="mailto:adrieli.silveira@arns.adm.br" newTab />
          <Icon src={internet} link="https://arns.adm.br/" />
        </IconBox>
      </Box>

      <Box>
        <Title>Perfis Profissionais</Title>
        <IconBox>
          <Icon
            src={LinkedIn}
            link="https://www.linkedin.com/in/adrieli-raquel-nascimento-silveira/"
            newTab
          />
        </IconBox>
      </Box>
    </Container>
  );
}

export default App;
