import styled from "styled-components";

interface IconProps {
  src: string;
  link: string;
  newTab?: boolean;
}

export default function Icon({ src, link, newTab }: IconProps) {
  const Icon = styled.img`
    width: 56px;
    background-color: #4f4f4f;
    padding: 12px;
    border-radius: 8px;
  `;

  return (
    <>
      <a href={link} target={newTab ? "_blank" : ""}>
        <Icon src={src} />
      </a>
    </>
  );
}
